import { required } from 'vuelidate/lib/validators';

export default {
    validations: {
        technology: {
            name: { required },
            description: { required },
            key: {
                required,
                onlyAlphabetically: val => {
                    if (val) {
                        const result = val.match(/[^a-z0-9]/g, '');

                        return !result || result.length === 0;
                    }

                    return true;
                }
            }
        },
        iconName: { required }
    },
    computed: {
        nameErrors() {
            const errors = [];
            if (!this.$v.technology.name.$dirty) return errors;
            !this.$v.technology.name.required &&
                errors.push('Name is required.');

            return errors;
        },

        keyErrors() {
            const errors = [];
            if (!this.$v.technology.key.$dirty) return errors;
            !this.$v.technology.key.required && errors.push('Key is required.');
            !this.$v.technology.key.onlyAlphabetically &&
                errors.push(
                    'Key can contain only alphabetically chars (a-z and 0-9).'
                );

            return errors;
        },

        descriptionErrors() {
            const errors = [];
            if (!this.$v.technology.description.$dirty) return errors;
            !this.$v.technology.description.required &&
                errors.push('Description is required.');

            return errors;
        },

        iconErrors() {
            const errors = [];
            if (!this.$v.iconName.$dirty) return errors;
            !this.$v.iconName.required && errors.push('Icon is required.');

            return errors;
        }
    }
};

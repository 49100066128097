<template>
    <v-container fluid>
        <v-row>
            <v-col cols="12">
                <v-btn color="success" @click="addTechnologyModal = true">Add technology</v-btn>
            </v-col>
            <v-col cols="12">
                <v-data-table
                    :key="addTechnologyModal"
                    :headers="headers"
                    :items="technologies"
                    :items-per-page="10"
                    disable-sort
                    class="elevation-1"
                >
                    <template v-slot:item.actions="{ item }">
                        <v-icon @click="openEditModal(item)">edit</v-icon>
                        <v-icon @click="openDeleteModal(item)">delete</v-icon>
                    </template>
                </v-data-table>
            </v-col>
        </v-row>

        <add-edit-technology-modal
            :is-open="addTechnologyModal"
            :is-edit-mode="!!technologyToEdit"
            :technology-to-edit="technologyToEdit"
            @added="addTechnology"
            @updated="editTechnology"
            @close="addTechnologyModal = false; technologyToEdit = null"
        />
        <confirm-delete-modal
            :isOpen="!!technologyToDelete"
            @disagree="technologyToDelete = null"
            @agree="removeTechnology"
        />
    </v-container>
</template>

<script>
import { mapActions } from 'vuex';
import AddEditTechnologyModal from '@/components/technologies/AddEditTechnologyModal';
import ConfirmDeleteModal from '@/components/modals/ConfirmDeleteModal';

export default {
    components: {
        AddEditTechnologyModal,
        ConfirmDeleteModal
    },

    data: () => ({
        technologies: [],
        total: 0,
        headers: [
            {
                text: 'Id',
                value: 'id',
                align: 'center'
            },
            { text: 'Name', value: 'name', align: 'center' },
            { text: 'Description', value: 'description', align: 'center' },
            {
                text: 'Actions',
                value: 'actions',
                align: 'center'
            }
        ],
        addTechnologyModal: false,
        technologyToEdit: null,
        technologyToDelete: null
    }),

    async mounted() {
        try {
            const technologies = await this.getTechnologies();

            this.technologies = technologies.rows;
            this.total = technologies.count;
        } catch (err) {
            console.error(err);

            this.$toasted.error('Something went wrong!');
        }
    },

    methods: {
        ...mapActions({
            getTechnologies: 'technologies/getTechnologies',
            deleteTechnology: 'technologies/deleteTechnology'
        }),

        addTechnology(technology) {
            this.technologies = [technology, ...this.technologies];
            this.addTechnologyModal = false;
        },

        openEditModal(technology) {
            this.technologyToEdit = technology;
            this.addTechnologyModal = true;
        },

        editTechnology(technology) {
            const editingIndex = this.technologies.findIndex(
                t => t.id === technology.id
            );

            if (editingIndex !== -1) {
                this.technologies[editingIndex] = { ...technology };
            }

            this.technologyToEdit = null;
            this.addTechnologyModal = false;
        },

        openDeleteModal(technology) {
            this.technologyToDelete = technology;
        },

        async removeTechnology() {
            try {
                await this.deleteTechnology(this.technologyToDelete.id);

                const technologyIndex = this.technologies.findIndex(
                    technology => {
                        return technology.id === this.technologyToDelete.id;
                    }
                );

                this.technologies.splice(technologyIndex, 1);

                this.technologyToDelete = null;

                this.$toasted.success('Technology has been removed!');
            } catch (err) {
                console.error(err);

                this.$toasted.error('Something went wrong!');
            }
        }
    }
};
</script>

<style>
</style>
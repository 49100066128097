<template>
    <v-dialog v-model="isOpen" persistent max-width="600px">
        <v-card>
            <v-card-title>
                <span class="headline">Add technology</span>
            </v-card-title>
            <v-card-text>
                <v-container>
                    <v-row>
                        <v-col cols="12" sm="6">
                            <v-text-field
                                v-model="technology.name"
                                label="Name"
                                :error-messages="nameErrors"
                                @input="
                                    $v.technology.name.$touch();
                                    generateKey();
                                "
                                @blur="$v.technology.name.$touch()"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6">
                            <v-text-field
                                v-model="technology.key"
                                label="Key"
                                :error-messages="keyErrors"
                                @input="$v.technology.key.$touch()"
                                @blur="$v.technology.key.$touch()"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12">
                            <v-textarea
                                v-model="technology.description"
                                label="Description"
                                :error-messages="descriptionErrors"
                                @input="$v.technology.description.$touch()"
                                @blur="$v.technology.description.$touch()"
                            ></v-textarea>
                        </v-col>
                        <template v-if="technology.icon">
                            <v-col cols="12" align="center" justify="center">
                                <img
                                    :src="iconUrl"
                                    max-height="150"
                                    style="background-color: #eee;"
                                />
                            </v-col>
                            <v-col cols="12" align="center" justify="center">
                                <v-icon @click="deleteIcon">delete</v-icon>
                            </v-col>
                        </template>
                        <v-col cols="12">
                            <v-text-field
                                :value="iconName"
                                label="Icon"
                                prepend-icon="attach_file"
                                readonly="readonly"
                                :error-messages="iconErrors"
                                @input="$v.iconName.$touch()"
                                @blur="$v.iconName.$touch()"
                                @click="pickIcon"
                            />
                            <input
                                type="file"
                                style="display: none"
                                ref="icon"
                                accept="image/*"
                                @change="onIconChange"
                            />
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeModal()">
                    Close
                </v-btn>
                <v-btn color="blue darken-1" text @click="save()">Save</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import { mapActions } from 'vuex';
import TechnologyValidation from './technology-validation.mixin';

export default {
    props: {
        isOpen: {
            type: Boolean,
            required: true,
            default: false
        },

        isEditMode: {
            type: Boolean,
            required: true,
            default: false
        },

        technologyToEdit: {
            type: Object,
            required: false,
            default: () => {}
        }
    },

    mixins: [TechnologyValidation],

    computed: {
        iconUrl() {
            if (!this.technology.icon) {
                return '';
            }

            const backendUrl = process.env.VUE_APP_MEDIA_URL;

            return `${backendUrl}/technologies/${this.technology.icon}`;
        }
    },

    watch: {
        isEditMode() {
            if (this.isEditMode) {
                this.technology = { ...this.technologyToEdit };
                this.iconName = this.technologyToEdit.icon;
            }
        }
    },

    data: () => ({
        technology: {},
        iconName: ''
    }),

    methods: {
        ...mapActions({
            uploadIcon: 'technologies/uploadIcon',
            addTechnology: 'technologies/addTechnology',
            updateTechnology: 'technologies/updateTechnology'
        }),

        async save() {
            this.$v.$touch();
            if (this.$v.$invalid) {
                return;
            }

            try {
                if (this.isEditMode) {
                    const technology = await this.updateTechnology({
                        id: this.technologyToEdit.id,
                        technologyData: this.technology
                    });

                    this.$emit('updated', technology);
                    this.$toasted.success('Technology has been updated!');
                } else {
                    const technology = await this.addTechnology(
                        this.technology
                    );

                    this.$emit('added', technology);
                    this.$toasted.success('Technology has been added!');
                }

                this.deleteIcon();
                this.technology = {};
                this.$v.$reset();
            } catch (err) {
                console.error(err);

                if (
                    err.response.status === 422 &&
                    err.response.data.errors &&
                    err.response.data.errors.length
                ) {
                    this.$toasted.error(err.response.data.errors[0]);

                    return;
                }

                this.$toasted.error('Something went wrong!');
            }
        },

        closeModal() {
            this.$emit('close');
            this.deleteIcon();
            this.technology = {};
            this.$v.$reset();
        },

        pickIcon() {
            this.$refs.icon.click();
        },

        async onIconChange() {
            const icons = this.$refs.icon.files;

            if (!icons || icons.length === 0) {
                return;
            }

            try {
                const uploadedIcon = await this.uploadIcon({
                    icon: icons[0]
                });

                this.technology.icon = uploadedIcon.filename;
                this.iconName = icons[0].name;
                this.$forceUpdate();
            } catch (err) {
                console.error(err);

                this.$toasted.error('Something went wrong!');
            }
        },

        deleteIcon() {
            this.technology.icon = null;
            this.iconName = '';

            const input = this.$refs.icon;
            input.type = 'text';
            input.type = 'file';
        },

        generateKey() {
            const key = this.technology.name.toLowerCase();

            if (key) {
                this.technology.key = key.replace(/[^a-z0-9]/g, '');
            }
        }
    }
};
</script>
